import React, { useState } from "react";
import { createStyles, Text, Tooltip, Modal, Divider } from "@mantine/core";
import { Circle, AlertTriangle, PlayCard, Check } from "tabler-icons-react";
import { useDictionary } from "i18n/dictionary";
import LeadSection from "./LeadSection";
import BattleCards from "pages/Admin/BattleCards";
import { showNotification } from "@mantine/notifications";
import { addSpaceBeforeUpperCase } from "utils/helpers";

const useStyles = createStyles(theme => ({
	text: { fontSize: theme.fontSizes.sm },
	score1: { fill: theme.colors.green[6], verticalAlign: "middle", strokeWidth: 1 },
	score2: { fill: theme.colors.yellow[6], verticalAlign: "middle", strokeWidth: 1 },
	score3: { fill: theme.colors.red[7], verticalAlign: "middle", strokeWidth: 1 },
	score4: { verticalAlign: "middle", strokeWidth: 1 },
	warning: { stroke: theme.colors.red[8], verticalAlign: "middle" },
	playCard: { stroke: theme.colors.blue[7], verticalAlign: "middle", marginLeft: 4 },
	pointer: { cursor: "pointer" },
}));

export default function LeadScores({ label, height, lead, company }) {
	const { classes } = useStyles();
	const dict = useDictionary("leadScore");
	const [cardEntityId, setCardEntityId] = useState(null);

	const getScoreClass = score => {
		return score > 39 && classes.score1 || score > 29 && classes.score2 || score > 19 && classes.score3 || classes.score4;
	};
	const onIndustryClick = (e, score) => {
		if (e.ctrlKey) {
			navigator.clipboard.writeText(score.positiveWords?.replaceAll(",", " - "));
			showNotification({ icon: <Check size={18} />, color: "teal", message: dict.copiedToClipboard });
		} else if (score.cardCount !== 0)
			setCardEntityId(score.industryId);
	};
	const content = lead?.scores?.length === 0
		? (!company?.website && <Text>{dict.enterWebsite}</Text>)
		: lead?.scores?.map(score =>
			<tr key={score.id}>
				<td>
					<Circle className={getScoreClass(Math.round(score.score))} />
				</td>
				<td onClick={e => onIndustryClick(e, score)} className={score.cardCount > 0 && classes.pointer || undefined}>
					<Tooltip multiline width={score.positiveWords == null ? 0 : 400} withArrow transition="fade" transitionDuration={200}
						label={score.positiveWords?.replaceAll(",", " - ")}>
						<Text className={classes.text}>
							{Math.round(score.score)} - {score.industry}
							{score.cardCount > 0 && <PlayCard className={classes.playCard} size="20" />}
						</Text>
					</Tooltip>
				</td>
				<td>
					{score.negativeWords &&
						<Tooltip multiline withArrow transition="fade" transitionDuration={200} width={75}
							label={score.negativeWords?.replaceAll(",", " - ")}>
							<span><AlertTriangle className={classes.warning} size="24" /></span>
						</Tooltip>
					}
				</td>
			</tr>
		);

	const aiContent = lead?.aiData?.map((data, i) =>
		<tr key={i}>
			<td><Text size="sm">{addSpaceBeforeUpperCase(data.property)}</Text></td>
			<td><Text size="sm">{data.value}</Text></td>
		</tr>
	);

	return (
		<>
			<LeadSection label={label} maxHeight={height - 55} minHeight={height}>
				<table style={{ width: "100%" }}><tbody>{content}</tbody></table>
				{lead?.aiData?.length > 0 &&
					<>
						<Divider label={dict.aiScore} labelPosition="center" />
						<table style={{ width: "100%" }} cellPadding={0} cellSpacing={0}><tbody>{aiContent}</tbody></table>
					</>
				}
			</LeadSection>
			<Modal opened={cardEntityId != null} centered size="85%" onClose={() => setCardEntityId(null)} title={dict.battleCard}>
				<BattleCards entityType={1} entityId={cardEntityId} readOnly />
			</Modal>
		</>
	);
}